.grid {
  @apply -mt-2xl pt-2xl md:bg-no-repeat md:bg-[size:100vw] md:bg-[url('https://belonghome.imgix.net/home-page/grid-background.svg')] md:bg-[position:bottom_-44vw_center]  min-[1920px]:bg-[position:bottom_-48vw_center];
}

.gradient {
  background: linear-gradient(16.79deg, rgb(1 157 255), #dcf2ff);

  @apply absolute h-[300px] -top-[220px] inset-x-0 blur-[300px] w-full md:h-[90px] md:-top-[80px];
}

.videoTitle {
  max-width: 600px;

  @screen md {
    max-width: 780px;
  }
}

.videoContainer {
  @apply min-w-[350px] w-full;
}

.videoContainer video {
  @apply rounded-none max-h-[90vh];
}

.featureTitle {
  @apply md:w-[360px] xl:w-[450px] !important;
}

.featureDescription {
  @apply md:w-[600px] xl:w-[700px] !important;
}

.ownRentalTitleWrapper {
  @apply flex flex-col p-lg pb-xl gap-xs text-center lg:pb-[32px] min-[1100px]:pb-xl !important;
}

.ownRentalTitle {
  @apply w-7xl text-center mx-auto md:w-auto xl:w-[350px];
}

.ownRentalDescription {
  @apply w-[285px] mx-auto text-center sm:max-w-2xl sm:w-auto md:max-w-xl lg:max-w-[340px] min-[1100px]:max-w-none min-[1100px]:min-h-0 lg:min-h-labeled-fields lg:w-[350px] xl:w-auto xl:max-w-[450px] !important;
}

.ownRentalButton {
  @apply mx-auto mb-[274px] min-[406px]:mb-[274px] min-[435px]:mb-7xl sm:mb-[324px] min-[589px]:mb-[300px] md:mb-[320px] xl:mt-0 xl:mb-7xl !important;
}

.ownRentalImage {
  @apply absolute w-auto h-auto inset-x-0 m-auto bottom-[25px] scale-[1.35] min-[406px]:bottom-sm min-[406px]:scale-125 sm:scale-150 sm:bottom-[44px] md:scale-[1.4] md:bottom-[35px] lg:bottom-[25px] lg:scale-125 xl:scale-100 xl:bottom-0 !important;
}

.rentingAdvantagesContainer {
  @apply flex flex-col p-lg pb-xl gap-xs text-left lg:pb-[32px] min-[1100px]:pb-xl !important;
}

.rentingAdvantagesTitle {
  @apply text-center mx-auto w-[280px] md:w-auto lg:w-[340px] !important;
}

.rentingAdvantagesDescription {
  @apply max-w-3xl text-center mx-auto sm:max-w-2xl sm:w-auto lg:min-h-labeled-fields lg:max-w-md min-[1100px]:max-w-none min-[1100px]:min-h-0 !important;
}

.rentingAdvantagesButton {
  @apply mx-auto mb-7xl sm:mb-[300px] md:mb-[320px] xl:mb-7xl !important;
}

.rentingAdvantagesImage {
  @apply w-auto h-auto inset-x-0 absolute m-auto bottom-[37px] scale-150 sm:bottom-[46px] sm:scale-[1.55] min-[620px]:scale-[1.55] min-[620px]:bottom-[46px] min-[720px]:scale-[1.65] min-[720px]:bottom-[56px] md:scale-150 md:bottom-[44px] lg:scale-[1.35] lg:bottom-[33px] xl:scale-100 xl:bottom-0 !important;
}

.ctasTitle {
  text-align: center;
  max-width: 380px;
  @screen md {
    max-width: 440px;
  }
}

.cta1Body {
  @apply max-w-[320px] md:max-w-none;
}

.cta2Body {
  max-width: 377px;
}

.cta1Body {
  max-width: 359px;
}

.cta1ImgContainer {
  max-width: 463px;
}

.cta2ImgContainer {
  max-width: 482px;
}

.title {
  @apply max-w-[824px] xl:max-w-[865px];
}

.testimonialsTitle {
  max-width: 285px;
}
